
.firebaseui-idp-button {
  @apply bg-white dark:bg-neutral-700 !important;
  @apply rounded-full !important;
}

@media (prefers-color-scheme: light) {
  [data-provider-id="password"] > span > .firebaseui-idp-icon{
    filter: invert(1);
  }
}

.firebaseui-idp-text {
  @apply text-neutral-700 dark:text-white !important;
}

